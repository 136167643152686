import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

const Body = styled.div`
  margin-top: 15vh;
  max-width: 600px;
  font-size: calc(1.1875rem);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 2rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  li {
    margin: 0.5rem;
  }
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
`;

const IndexPage = () => {
  return (
    <Layout pageTitle="Fabio de Sousa">
      <Body>
        <h1>Hello!</h1>
        <p>
          I'm a Software Engineer/Data Guy/Person Who Builds Things™ based in
          Rockland, Maine.
        </p>
        <p>
          Please reach out if you have an annoying manual process you'd like to
          automate, a spreadsheet you'd like to murder, or any interesting
          problem you'd like to talk about. If I can point you in the right
          direction I will :)
        </p>
        <Row>fabio@desousa.studio</Row>
      </Body>
    </Layout>
  );
};

export default IndexPage;
